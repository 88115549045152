import * as React from 'react';
import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Avatar from '@mui/joy/Avatar';
export default function Header() {


    return (
        <Card
            variant="outlined"
            orientation="vertical"
            size="sm"
            sx={{
                overflow: 'auto',
                resize: 'horizontal',
                borderColor:'white',
                alignItems: 'center',
                p:.5,
                background: 'linear-gradient(to bottom, black, white)'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Avatar src="/logomark.png" size="md" />
            </Box>
            <CardContent>
                <Typography level="title-lg" align="center" sx={{fontWeight:'800', color: 'white'}}>URBANDEALS</Typography>
                <Typography level="body-xs" align="center" sx={{color:'black'}}>¡Encuentra oportunidades de inversión en departamentos en Lima!</Typography>
            </CardContent>
        </Card>
    );
}