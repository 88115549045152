import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import IconButton, { iconButtonClasses } from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

export default function Pagination({ currentPage, numpages, setPagination }) {


    return (
        <div>
            <Box
                sx={{
                    display: { xs: 'flex' },
                    alignItems: 'center',
                    mx: 2,
                    my: 1,
                }}
            >
                <IconButton
                    aria-label="previous page"
                    variant="outlined"
                    color="neutral"
                    size="sm"
                    onClick={(event) => {
                        if((currentPage-1)>0)
                            setPagination(currentPage - 1);
                    }}
                >
                    <ArrowBackIosRoundedIcon />
                </IconButton>
                <Typography level="body-md" mx="auto">
                    {`Página  ${currentPage} de ${numpages}`}
                </Typography>
                <IconButton
                    aria-label="next page"
                    variant="outlined"
                    color="neutral"
                    size="sm"
                    onClick={(event) => {
                        if ((currentPage + 1) <= numpages)
                            setPagination((currentPage + 1));
                    }}
                >
                    <ArrowForwardIosRoundedIcon />
                </IconButton>
            </Box>
        </div>
    );
}