import React from "react";
import ReactDOMServer from 'react-dom/server';

import 'leaflet/dist/leaflet.css'
import "leaflet/dist/images/marker-shadow.png";
import L from "leaflet";
import { MapContainer, TileLayer, GeoJSON, Tooltip, useMapEvents } from 'react-leaflet'
import styles from "./styles.module.css";
import * as turf from '@turf/turf';
import Button from '@mui/joy/Button';

import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';


const svgIcon = L.divIcon({
    html: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.666672 9C0.666672 13.6024 4.39763 17.3333 9 17.3333C13.6024 17.3333 17.3333 13.6024 17.3333 9C17.3333 4.39763 13.6024 0.666672 9 0.666672C4.39763 0.666672 0.666672 4.39763 0.666672 9Z" fill="#FF44C5"/>
    </svg>       
    `,
    className: "svg-icon",
    iconSize: [10, 10],
    iconAnchor: [10, 10]
});

const formatLargeNumber = (number) => {
    if (number >= 1000000) {
        return (number / 1000000).toFixed(0) + 'M';
    } else if (number >= 1000) {
        return (number / 1000).toFixed(0) + 'K';
    } else {
        return number.toString();
    }
};


function MapComponentController({data}) {
    
    const map = useMapEvents({
      click: () => {
        //console.log('map center:', map.getCenter())
        console.log('bounds', map.getBounds());
      },
      moveend:() => {
        const bounds = map.getBounds();
        console.log('Updated Bounds:', bounds);
      }
    });

    const handleMoveEnd = (event) => {
        const map = event.target;
        const bounds = map.getBounds();
        console.log('Updated Bounds:', bounds);
    };

    var bbox = turf.bbox({
      type: 'FeatureCollection',
      features: data.features
    });
    //map.setMaxBounds([[bbox[1], bbox[0]], [bbox[3], bbox[2]]])

    return null
  }


const Mapa = ({ data, filters }) => {
    const position = [-12.102249299423637, -77.058405876159682]
    
    const buildingCard = (feature) => {
        const nombreProyecto = feature.properties.buildings[0].properties.name;
        const qbuildings = feature.properties.buildings.length;
        const prices = feature.properties.buildings.map(building => building.properties.price);
        const contactos = feature.properties.buildings.map(building => building.properties.contacto_wasap);
        const metrajes = feature.properties.buildings.map(building => building.properties.area);

        const minPrice = Math.min(...prices);
        const maxPrice = Math.max(...prices);
        const minArea = Math.min(...metrajes);
        const maxArea = Math.max(...metrajes);


        return <div>
        <div>
          <h3 style={{'font-size': '18px'}}>{nombreProyecto}</h3>
          <p style={{'font-weight': 400}}>{qbuildings} departamentos</p>
        </div>
        <div>
          <div>
            <p>Precios desde: <b>S/.{formatLargeNumber(minPrice)}</b> hasta <b>S/.{formatLargeNumber(maxPrice)}</b></p>
            <p>Departamentos desde <b>{minArea}m²</b>  hasta <b>{maxArea}m²</b> </p>
            <a target="_blank" href={contactos[0]+"Hola! Estoy interesado en uno de los departamentos del proyecto "+nombreProyecto}>Contactar</a>
          </div>
        </div>
      </div>
    };


    return (
        <>
            <MapContainer id={'map'} center={position} zoom={10} scrollWheelZoom={true} style={{ width: '100%', height: '100%', marginTop: '10px' }}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                <GeoJSON key={JSON.stringify(data.features)}
                    onEachFeature={(feature, layer) => {
                        layer.setIcon(svgIcon);
                        layer.on({
                            click: (event) => {
                                const districtName = event.target.feature.properties;
                                console.log(districtName);
                                console.log(districtName + " - " + layer.feature.properties.price_bins);

                            }
                        })
                        let tooltipContent = "<div>" + feature.properties.buildings.length + "</div>";
                        let popupContent = ReactDOMServer.renderToString(buildingCard(feature));

                        let tooltipOptions = { permanent: true, opacity: 0.75, className: styles.leaflet_tooltip }
                        layer.bindTooltip(tooltipContent, tooltipOptions);
                        layer.bindPopup(popupContent, { permanent: true, opacity: 0.75, className: styles.leaflet_popup});
                    }}
                    data={JSON.parse(JSON.stringify(data))} />

            <MapComponentController data={data}></MapComponentController>
            </MapContainer>
        </>
    )
};

export default Mapa;