import * as React from 'react';
import { useState, useEffect } from 'react';
import AccordionGroup from '@mui/joy/AccordionGroup';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails, {
    accordionDetailsClasses,
} from '@mui/joy/AccordionDetails';
import AccordionSummary, {
    accordionSummaryClasses,
} from '@mui/joy/AccordionSummary';
import Switch from '@mui/joy/Switch';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Avatar from '@mui/joy/Avatar';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import ListItemContent from '@mui/joy/ListItemContent';
import Checkbox from '@mui/joy/Checkbox';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import Slider from '@mui/joy/Slider';

import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import BedroomChildOutlinedIcon from '@mui/icons-material/BedroomChildOutlined';
import CarpenterOutlinedIcon from '@mui/icons-material/CarpenterOutlined';
import PriceCheckOutlinedIcon from '@mui/icons-material/PriceCheckOutlined';
import StraightenOutlinedIcon from '@mui/icons-material/StraightenOutlined';
import Done from '@mui/icons-material/Done';

function valueText(value) {
    return `${value}°C`;
}

const getUniqueValues = (data, property) => {
    const uniqueValuesSet = new Set();
    data.forEach((feature) => {
        uniqueValuesSet.add(feature.properties[property]);
    });

    const uniqueValuesArray = Array.from(uniqueValuesSet);

    return uniqueValuesArray.slice().sort();
};



export default function Filters({ filters, setFilterOptions, fullData }) {

    console.log(filters);

    const segmentos = getUniqueValues(fullData, 'segmento');
    const distritos = getUniqueValues(fullData, 'distrito');
    const habs = getUniqueValues(fullData, 'hab');
    const etapas = getUniqueValues(fullData, 'etapa');
    const oportunidades = getUniqueValues(fullData, 'oportunidad');


    const [selectedSegmentos, setSelectedSegmentos] = useState([]);
    const [selectedDistritos, setSelectedDistritos] = useState([]);
    const [selectedHabs, setSelectedHabs] = useState([]);
    const [selectedEtapas, setSelectedEtapas] = useState([]);
    const [selectedOportunidades, setSelectedOportunidades] = useState([]);

    const [valuem2, setValuem2] = useState([20, 37]);

    useEffect(() => {
        console.log('Value selectedSegmentos has changed:', selectedSegmentos);
        console.log('Value selectedDistritos has changed:', selectedDistritos);
        console.log('Value selectedHabs has changed:', selectedHabs);
        console.log('Value selectedEtapas has changed:', selectedEtapas);
        console.log('Value selectedOportunidades has changed:', selectedOportunidades);

        setFilterOptions({
            segmento: selectedSegmentos.length > 0 ? selectedSegmentos : 'all',
            distrito: selectedDistritos.length > 0 ? selectedDistritos : 'all',
            hab: selectedHabs.length > 0 ? selectedHabs : 'all',
            etapa: selectedEtapas.length > 0 ? selectedEtapas : 'all',
            oportunidad: selectedOportunidades.length > 0 ? selectedOportunidades : 'all'
        });

    }, [selectedSegmentos, selectedDistritos, selectedHabs, selectedEtapas, selectedOportunidades]);

    const handleChange = (event, newValue) => {
        setValuem2(newValue);
    };

    return (
        <AccordionGroup
            variant="plain"
            transition="0.2s"
            sx={{
                maxWidth: 400,
                borderRadius: 'md',
                [`& .${accordionDetailsClasses.content}.${accordionDetailsClasses.expanded}`]:
                {
                    paddingBlock: '1rem',
                },
                [`& .${accordionSummaryClasses.button}`]: {
                    paddingBlock: '1rem',
                },
            }}
        >

            <Accordion expanded>
                <AccordionSummary>
                    <Avatar color="primary">
                        <PriceCheckOutlinedIcon />
                    </Avatar>
                    <ListItemContent>
                        <Typography level="title-md">Oferta</Typography>
                        <Typography level="body-sm">
                            Filtra los departamentos que tienen un precio por debajo del estimado general.
                        </Typography>
                    </ListItemContent>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack pl={3}>
                        <List
                            orientation="horizontal"
                            wrap
                            sx={{
                                '--List-gap': '18px',
                                '--ListItem-radius': '20px',
                                '--ListItem-minHeight': '32px',
                                '--ListItem-gap': '4px',
                            }}
                        >
                            {oportunidades.map(
                                (item, index) => (
                                    <ListItem key={item}>
                                        {selectedOportunidades.includes(item) && (
                                            <Done
                                                fontSize="md"
                                                color="primary"
                                                sx={{ ml: -0.5, zIndex: 2, pointerEvents: 'none' }}
                                            />
                                        )}

                                        <Checkbox
                                            size="sm"
                                            disableIcon
                                            overlay
                                            label={item ? "Buena oportunidad" : "Precio regular"}
                                            checked={selectedOportunidades.includes(item)}
                                            variant={selectedOportunidades.includes(item) ? 'soft' : 'outlined'}
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    console.log(item);
                                                    setSelectedOportunidades((val) => [...val, item]);
                                                } else {
                                                    setSelectedOportunidades((val) => val.filter((text) => text !== item));
                                                }
                                            }}
                                            slotProps={{
                                                action: ({ checked }) => ({
                                                    sx: checked
                                                        ? {
                                                            border: '1px solid',
                                                            borderColor: 'primary.500',
                                                        }
                                                        : {},
                                                }),
                                            }}
                                        />
                                    </ListItem>
                                ),
                            )}
                        </List>
                    </Stack>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary>
                    <Avatar color="primary">
                        <LocationOnOutlinedIcon />
                    </Avatar>
                    <ListItemContent>
                        <Typography level="title-md">Segmentos</Typography>
                        <Typography level="body-sm">
                            Elige la zona de Lima donde quieres vivir
                        </Typography>
                    </ListItemContent>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack pl={3}>
                        <List
                            orientation="horizontal"
                            wrap
                            sx={{
                                '--List-gap': '18px',
                                '--ListItem-radius': '20px',
                                '--ListItem-minHeight': '32px',
                                '--ListItem-gap': '4px',
                            }}
                        >
                            {segmentos.map(
                                (item, index) => (
                                    <ListItem key={item}>
                                        {selectedSegmentos.includes(item) && (
                                            <Done
                                                fontSize="md"
                                                color="primary"
                                                sx={{ ml: -0.5, zIndex: 2, pointerEvents: 'none' }}
                                            />
                                        )}

                                        <Checkbox
                                            size="sm"
                                            disableIcon
                                            overlay
                                            label={item}
                                            checked={selectedSegmentos.includes(item)}
                                            variant={selectedSegmentos.includes(item) ? 'soft' : 'outlined'}
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    setSelectedSegmentos((val) => [...val, item]);
                                                } else {
                                                    setSelectedSegmentos((val) => val.filter((text) => text !== item));
                                                }
                                            }}
                                            slotProps={{
                                                action: ({ checked }) => ({
                                                    sx: checked
                                                        ? {
                                                            border: '1px solid',
                                                            borderColor: 'primary.500',
                                                        }
                                                        : {},
                                                }),
                                            }}
                                        />
                                    </ListItem>
                                ),
                            )}
                        </List>
                    </Stack>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary>
                    <Avatar color="primary">
                        <LocationCityOutlinedIcon />
                    </Avatar>
                    <ListItemContent>
                        <Typography level="title-md">Distrito</Typography>
                        <Typography level="body-sm">
                            Selecciona el distrito.
                        </Typography>
                    </ListItemContent>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack pl={3}>
                        <List
                            orientation="horizontal"
                            wrap
                            sx={{
                                '--List-gap': '18px',
                                '--ListItem-radius': '20px',
                                '--ListItem-minHeight': '32px',
                                '--ListItem-gap': '4px',
                            }}
                        >
                            {distritos.map(
                                (item, index) => (
                                    <ListItem key={item}>
                                        {selectedDistritos.includes(item) && (
                                            <Done
                                                fontSize="md"
                                                color="primary"
                                                sx={{ ml: -0.5, zIndex: 2, pointerEvents: 'none' }}
                                            />
                                        )}

                                        <Checkbox
                                            size="sm"
                                            disableIcon
                                            overlay
                                            label={item}
                                            checked={selectedDistritos.includes(item)}
                                            variant={selectedDistritos.includes(item) ? 'soft' : 'outlined'}
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    setSelectedDistritos((val) => [...val, item]);
                                                } else {
                                                    setSelectedDistritos((val) => val.filter((text) => text !== item));
                                                }
                                            }}
                                            slotProps={{
                                                action: ({ checked }) => ({
                                                    sx: checked
                                                        ? {
                                                            border: '1px solid',
                                                            borderColor: 'primary.500',
                                                        }
                                                        : {},
                                                }),
                                            }}
                                        />
                                    </ListItem>
                                ),
                            )}
                        </List>
                    </Stack>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary>
                    <Avatar color="primary">
                        <BedroomChildOutlinedIcon />
                    </Avatar>
                    <ListItemContent>
                        <Typography level="title-md">Dormitorios</Typography>
                        <Typography level="body-sm">
                            Elige la cantidad de dormitorios que se acomodan a ti.
                        </Typography>
                    </ListItemContent>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack pl={3}>
                        <List
                            orientation="horizontal"
                            wrap
                            sx={{
                                '--List-gap': '18px',
                                '--ListItem-radius': '20px',
                                '--ListItem-minHeight': '32px',
                                '--ListItem-gap': '4px',
                            }}
                        >
                            {habs.map(
                                (item, index) => (
                                    <ListItem key={item}>
                                        {selectedHabs.includes(item) && (
                                            <Done
                                                fontSize="md"
                                                color="primary"
                                                sx={{ ml: -0.5, zIndex: 2, pointerEvents: 'none' }}
                                            />
                                        )}

                                        <Checkbox
                                            size="sm"
                                            disableIcon
                                            overlay
                                            label={item}
                                            checked={selectedHabs.includes(item)}
                                            variant={selectedHabs.includes(item) ? 'soft' : 'outlined'}
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    setSelectedHabs((val) => [...val, item]);
                                                } else {
                                                    setSelectedHabs((val) => val.filter((text) => text !== item));
                                                }
                                            }}
                                            slotProps={{
                                                action: ({ checked }) => ({
                                                    sx: checked
                                                        ? {
                                                            border: '1px solid',
                                                            borderColor: 'primary.500',
                                                        }
                                                        : {},
                                                }),
                                            }}
                                        />
                                    </ListItem>
                                ),
                            )}
                        </List>
                    </Stack>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary>
                    <Avatar color="primary">
                        <CarpenterOutlinedIcon />
                    </Avatar>
                    <ListItemContent>
                        <Typography level="title-md">Etapa</Typography>
                        <Typography level="body-sm">
                            Elige si prefieres dptos en fase de construcción, en planos o para entrega inmediata.
                        </Typography>
                    </ListItemContent>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack pl={3}>
                        <List
                            orientation="horizontal"
                            wrap
                            sx={{
                                '--List-gap': '18px',
                                '--ListItem-radius': '20px',
                                '--ListItem-minHeight': '32px',
                                '--ListItem-gap': '4px',
                            }}
                        >
                            {etapas.map(
                                (item, index) => (
                                    <ListItem key={item}>
                                        {selectedEtapas.includes(item) && (
                                            <Done
                                                fontSize="md"
                                                color="primary"
                                                sx={{ ml: -0.5, zIndex: 2, pointerEvents: 'none' }}
                                            />
                                        )}

                                        <Checkbox
                                            size="sm"
                                            disableIcon
                                            overlay
                                            label={item}
                                            checked={selectedEtapas.includes(item)}
                                            variant={selectedEtapas.includes(item) ? 'soft' : 'outlined'}
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    setSelectedEtapas((val) => [...val, item]);
                                                } else {
                                                    setSelectedEtapas((val) => val.filter((text) => text !== item));
                                                }
                                            }}
                                            slotProps={{
                                                action: ({ checked }) => ({
                                                    sx: checked
                                                        ? {
                                                            border: '1px solid',
                                                            borderColor: 'primary.500',
                                                        }
                                                        : {},
                                                }),
                                            }}
                                        />
                                    </ListItem>
                                ),
                            )}
                        </List>
                    </Stack>
                </AccordionDetails>
            </Accordion>

            {/* <Accordion>
                <AccordionSummary>
                    <Avatar color="primary">
                        <StraightenOutlinedIcon />
                    </Avatar>
                    <ListItemContent>
                        <Typography level="title-md">Metraje</Typography>
                        <Typography level="body-sm">
                            Elige el metraje que estás buscando.
                        </Typography>
                    </ListItemContent>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack spacing={1.5}>
                        <FormControl orientation="horizontal" sx={{ gap: 1 }}>
                            <Slider
                                getAriaLabel={() => 'Temperature range'}
                                value={valuem2}
                                onChange={handleChange}
                                valueLabelDisplay="auto"
                                getAriaValueText={valueText}
                            />
                        </FormControl>

                    </Stack>
                </AccordionDetails>
            </Accordion> */}
        </AccordionGroup>
    );
}