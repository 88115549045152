import * as React from 'react';
import { useState, useEffect } from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import Sheet from '@mui/joy/Sheet';
import Grid from '@mui/joy/Grid';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import Mapa from './components/MainMap';
import Filters from './components/FilteringSection';

import Switch from '@mui/joy/Switch';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import DepaList from './components/Gallery';
import deals from './data/deals20240411.json';
import fulldata from './data/deals20240411.json';
import Pagination from './components/Pagination';

import Box from '@mui/joy/Box';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import Header from './components/Header';


function turnIntoGeoJSON(data) {
  return {
    "type": "FeatureCollection",
    "features": data
  }
}


function App() {
  const [page, setPage] = useState(1);
  const [dark, setDark] = useState(false);
  const [numPages, setNumPages] = useState(10);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [filterOptions, setFilterOptions] = useState({ segmento: 'all', distrito: 'all', hab: 'all', etapa: 'all', oportunidad: 'all' });

  const handleSetFilterOptions = (newValue) => {
    setFilterOptions(newValue);
  }

  const handleSetPagination = (newvalue) => {
    setPage(newvalue);
  }

  const getUniqueBuildings = (data) => {
    const groupedFeatures = {};
    data.forEach((feature) => {
      const { coordinates } = feature.geometry;

      if (coordinates && coordinates.length === 2) {
        const [lat, lng] = coordinates;

        const key = `${lat}_${lng}`;
        if (!groupedFeatures[key]) {
          groupedFeatures[key] = { coordinates: [lat, lng], features: [] };
        }

        groupedFeatures[key].features.push(feature);
      }
    });

    const myMap = Object.entries(groupedFeatures).reduce((map, [key, value]) => map.set(key, value), new Map());

    const transformedArray = Array.from(myMap).map(([key, value]) => {
      return {
        "type": "Feature",
        "properties": {
          "buildings": value.features
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            key.split("_")[0],
            key.split("_")[1],
          ]
        }
      };
    });


    return turnIntoGeoJSON(transformedArray);

  }


  const filteredData = deals.features.filter(item => {
    return (
      (filterOptions.segmento === 'all' || filterOptions.segmento.includes(item.properties.segmento)) &&
      (filterOptions.distrito === 'all' || filterOptions.distrito.includes(item.properties.distrito)) &&
      (filterOptions.hab === 'all' || filterOptions.hab.includes(item.properties.hab)) &&
      (filterOptions.etapa === 'all' || filterOptions.etapa.includes(item.properties.etapa)) &&
      (filterOptions.oportunidad === 'all' || filterOptions.oportunidad.includes(item.properties.oportunidad)) &&
      (item.properties.segmento !== 'Provincia')
    );
  });

  useEffect(() => {
    console.log("filtro cambiado");
    console.log(filterOptions);
    console.log(filteredData);
    setNumPages(Math.floor(filteredData.length / itemsPerPage) + 1);
    setPage(1);
  }, [filterOptions]);


  const [index, setIndex] = React.useState(0);
  const colors = ['primary', 'primary', 'primary', 'primary'];


  return (
    <CssVarsProvider>
      <Sheet variant="outlined" sx={{
        p: 4, mx: 1, borderRadius: 10, display: { xs: 'none', md: 'flex' },
      }}>

        <Grid container spacing={1} style={{ width: '100%' }}>
          <Grid xs={8}>
            <FormControl
              orientation="horizontal"
              sx={{ justifyContent: 'space-between', gap: '10px' }}
            >
              <div>
                <FormLabel>Visualización</FormLabel>
                <FormHelperText sx={{ mt: 0 }}>Visualiza a través de un mapa o con fotografías en forma de galería.</FormHelperText>
              </div>
              <Switch
                startDecorator={<MapOutlinedIcon />}
                endDecorator={<SpaceDashboardOutlinedIcon />}
                checked={dark}
                onChange={(event) => setDark(event.target.checked)}
              />
            </FormControl>


          </Grid>

          <Grid xs={12} md={8} sx={{ height: '80vh' }}>
            {dark ? <Grid container
              direction="column"
              justifyContent="center" sx={{}}>
              <Pagination currentPage={page} numpages={numPages} setPagination={handleSetPagination}></Pagination>
              <Grid container
                direction="row"
                justifyContent="center"
                alignItems="flex-start" sx={{ height: '80vh', overflow: 'auto' }}>
                <DepaList data={turnIntoGeoJSON(filteredData.slice((page - 1) * itemsPerPage, (page) * itemsPerPage))} filters={filterOptions}></DepaList>
              </Grid>

            </Grid>
              :
              <Mapa data={getUniqueBuildings(filteredData)} filters={filterOptions}></Mapa>}
          </Grid>
          <Grid >
            <Filters filters={filterOptions} setFilterOptions={handleSetFilterOptions} fullData={fulldata.features}></Filters>
          </Grid>
        </Grid>

      </Sheet>
      <Sheet variant="plain" sx={{
        borderRadius: 5, display: { xs: 'flex', md: 'none' }, flexDirection: 'column', m: 0, p:0
      }}>
        <Box
          display="flex"
          direction="column"
          alignItems="flex-end"
          sx={{
            p: 0,
            bgcolor: `${colors[index]}`,
            height: '90vh',
            width: '100%'
          }}
        >
          {index == 0 && <Box sx={{ position: 'fixed', height: '100vh', top: 0, left: 0, right: 0 }}>
            <Header></Header>
            <Mapa data={getUniqueBuildings(filteredData)} filters={filterOptions}></Mapa>
          </Box>
          }

          {index == 1 && <Box sx={{ position: 'fixed', height: '100vh', top: 0, left: 0, right: 0 }}>
            <Header></Header>
            <Grid container
              direction="column"
              justifyContent="center" sx={{
                p: .5, mx: .5, display: { xs: 'flex', md: 'none' },
              }}>
              <Pagination currentPage={page} numpages={numPages} setPagination={handleSetPagination}></Pagination>
              <Grid container
                direction="row"
                flexWrap="wrap"
                alignItems="flex-start" sx={{ height: '80vh', overflow: 'auto', justifyContent: { xs: "center", sm: "center" } }} >
                <DepaList data={turnIntoGeoJSON(filteredData.slice((page - 1) * itemsPerPage, (page) * itemsPerPage))} filters={filterOptions}></DepaList>
              </Grid>

            </Grid>


          </Box>
          }

          {index == 2 && <Box sx={{ position: 'fixed', height: '100vh', top: 0, left: 0, right: 0, overflow: 'auto'}}>
            <Header></Header>
            
            <Filters filters={filterOptions} setFilterOptions={handleSetFilterOptions} fullData={fulldata.features}></Filters>
            
          </Box>
          }

          <Tabs
            size="lg"
            aria-label="Bottom Navigation"
            value={index}
            onChange={(event, value) => setIndex(value)}
            sx={(theme) => ({
              p: .5,
              borderRadius: 16,
              mx: 'auto',
              alignItems: 'center',
              boxShadow: theme.shadow.sm,
              '--joy-shadowChannel': theme.vars.palette[colors[index]].darkChannel,
              [`& .${tabClasses.root}`]: {
                py: 1,
                flex: 1,
                fontWeight: 'md',
                fontSize: 'md',
                [`&:not(.${tabClasses.selected}):not(:hover)`]: {
                  opacity: 0.7,
                },
              },
            })}
          >
            <TabList
              variant="plain"
              size="sm"
              disableUnderline
              sx={{ borderRadius: 'md', p: 0 }}
            >
              <Tab
                disableIndicator
                orientation="vertical"
                {...(index === 0 && { color: colors[0] })}
              >
                <ListItemDecorator>
                  <MapOutlinedIcon />
                </ListItemDecorator>
                Mapa
              </Tab>
              <Tab
                disableIndicator
                orientation="vertical"
                {...(index === 1 && { color: colors[1] })}
              >
                <ListItemDecorator>
                  <SpaceDashboardOutlinedIcon />
                </ListItemDecorator>
                Galería
              </Tab>
              <Tab
                disableIndicator
                orientation="vertical"
                {...(index === 2 && { color: colors[2] })}
              >
                <ListItemDecorator>
                  <TuneOutlinedIcon />
                </ListItemDecorator>
                Filtrar
              </Tab>
            </TabList>
          </Tabs>
        </Box>
      </Sheet>
    </CssVarsProvider>
  );
}

export default App;
