import * as React from 'react';
import { useState, useEffect } from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import Grid from '@mui/joy/Grid';
import Chip from '@mui/joy/Chip';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link } from '@mui/joy';


const formatLargeNumber = (number) => {
    if (number >= 1000000) {
        return (number / 1000000).toFixed(0) + 'M';
    } else if (number >= 1000) {
        return (number / 1000).toFixed(0) + 'K';
    } else {
        return number.toString();
    }
};


export default function DepaList({ data }) {


    return (
        data.features.map((item) => {
            return <Card
                variant="soft"
                size="md"
                sx={{
                    p: .5, m: .5, size: { xs: 'sm', sm: 'md' },
                    maxWidth:{ xs: '100%', sm: '220px'}
                }}>

                <Box>
                    <Typography level="title-lg" >{item.properties.name}</Typography>
                    <Typography level="body-sm">{item.properties.direcc} - {item.properties.distrito}</Typography>
                    <Chip
                        color={item.properties.oportunidad ? 'success' : 'primary'}
                        variant={item.properties.oportunidad ? 'solid' : 'outlined'}
                        size="sm"
                    >{item.properties.oportunidad ? 'Precio debajo del mercado' : 'Precio regular'}</Chip>

                </Box>

                <AspectRatio sx={{minWidth:{xs:'120px', sm:'220px'}, display:{xs:'none', sm:'flex'}, maxHeight:{xs:'200px'}}}>
                    <img
                        src={item.properties.img}
                        srcSet={item.properties.img}
                        loading="lazy"
                        alt=""
                    />
                </AspectRatio>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={.5} flexWrap="wrap" useFlexGap>
                    <Chip
                        color="primary"
                        variant="outlined"
                        size="sm"
                    >{Math.floor(item.properties.area)} m²</Chip>
                    <Chip
                        color="primary"
                        variant="outlined"
                        size="sm"
                    >{item.properties.hab} dorms</Chip>
                    <Chip
                        color="primary"
                        variant="outlined"
                        size="sm"
                    >{item.properties.etapa}</Chip>
                </Stack>

                <CardContent orientation="horizontal" sx={{ justifyContent: 'space-between', flexWrap:"wrap"}}>

                    <div>
                        <Typography level="body-xs">Precio:</Typography>
                        <Typography fontSize="md" fontWeight="lg">
                            S/{formatLargeNumber(item.properties.price)}
                        </Typography>
                    </div>
                    <Link href={item.properties.msge}>
                        <Button
                            variant="solid"
                            size="sm"
                            color="primary"
                            sx={{ ml: 'auto', alignSelf: 'center', fontWeight: 600 }}

                            startDecorator={<WhatsAppIcon></WhatsAppIcon>}
                        >
                            Contáctalos
                        </Button>
                    </Link>

                </CardContent>
            </Card >
        }
        )
    );
}